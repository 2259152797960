import fetch from 'auth/FetchInterceptor'

const pushService = {}

pushService.all = function () {
  return fetch({
    url: '/notification/all',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

pushService.save = function (data) {
  return fetch({
    url: '/notification',
    method: 'post',
    data: data
  })
}

export default pushService