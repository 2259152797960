import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import ConfiguracaoService from 'services/ConfiguracaoService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const getStatusWhatsapp = createAsyncThunk('configuracao/statuswhatsapp',async () => {
    const response = await ConfiguracaoService.statusWhatsapp()
    return response;
})

export const conectar = createAsyncThunk('configuracao/conectar',async () => {
    const response = await ConfiguracaoService.conectar()
    return response;
})

export const logout = createAsyncThunk('configuracao/logout',async () => {
    const response = await ConfiguracaoService.logout()
    return response;
})

export const configuracaoSlice = createSlice({
	name: 'configuracao',
	initialState,
	reducers: {
		
	},
    extraReducers: (builder) => {
		builder
			.addCase(getStatusWhatsapp.pending, (state) => {
				state.statusWhatsapp = null
				state.loading = true
			})
			.addCase(getStatusWhatsapp.fulfilled, (state, action) => {
				state.loading = false
				// state.redirect = '/'
				state.statusWhatsapp = action.payload
			})
			.addCase(getStatusWhatsapp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			}).addCase(conectar.pending, (state) => {
				state.loading = true
			})
			.addCase(conectar.fulfilled, (state, action) => {
				state.loading = false
				// state.redirect = '/'
				state.whatsapp = action.payload
			})
			.addCase(conectar.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			}).addCase(logout.pending, (state) => {
				state.loading = true
			})
			.addCase(logout.fulfilled, (state, action) => {
				state.loading = false
				// state.redirect = '/'

				state.statusWhatsapp = action.payload
				state.whatsapp = null
			})
			.addCase(logout.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const {
	showLoading,
} = configuracaoSlice.actions

export default configuracaoSlice.reducer