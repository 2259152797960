import fetch from 'auth/FetchInterceptor'

const checklistService = {}

checklistService.all = function () {
  return fetch({
    url: '/checklist/all',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

checklistService.id = function (data) {
  return fetch({
    url: `/checklist/${data}/obter`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

checklistService.criar = function (data) {
  return fetch({
    url: `/checklist/${data}/criar`,
    method: 'post',
    data: data
  })
}

export default checklistService