import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import BoletoService from 'services/BoletoService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const getAll = createAsyncThunk('boleto/all',async () => {
    const response = await BoletoService.all()
    return response;
})

export const atraso = createAsyncThunk('boleto/atraso',async () => {
    const response = await BoletoService.atrasado()
    return response;
})

export const cancelar = createAsyncThunk('boleto/cancelar',async (data, { rejectWithValue }) => {
	try {
		const response = await BoletoService.cancelar(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao cancelar boleto')
	}
})

export const confirmar = createAsyncThunk('boleto/confirmar',async (data, { rejectWithValue }) => {
	try {
		const response = await BoletoService.confirmar(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao confirmar boleto')
	}
})

export const reenviar = createAsyncThunk('boleto/reenviar',async (data, { rejectWithValue }) => {
	try {
		const response = await BoletoService.reenviar(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao reenviar boleto')
	}
})

export const gerarNf = createAsyncThunk('boleto/gerarnf',async (data, { rejectWithValue }) => {
	try {
		const response = await BoletoService.gerarNf(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao gerar nf')
	}
})

export const boletoSlice = createSlice({
	name: 'boleto',
	initialState,
	reducers: {
		
	},
    extraReducers: (builder) => {
		builder
			.addCase(getAll.pending, (state) => {
				state.loading = true
			})
			.addCase(getAll.fulfilled, (state, action) => {
				state.loading = false
				state.boletos = action.payload
			})
			.addCase(getAll.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(cancelar.pending, (state) => {
				state.loading = true
			})
			.addCase(cancelar.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/boleto/default'
				state.edicao = action.payload
			})
			.addCase(cancelar.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(confirmar.pending, (state) => {
				state.loading = true
			})
			.addCase(confirmar.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/boleto/default'
			})
			.addCase(confirmar.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(reenviar.pending, (state) => {
				state.loading = true
			})
			.addCase(reenviar.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/boleto/default'
				state.edicao = action.payload
			})
			.addCase(reenviar.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(gerarNf.pending, (state) => {
				state.loading = true
			})
			.addCase(gerarNf.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/boleto/default'
				state.nf = action.payload
			})
			.addCase(gerarNf.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.nf = false
			})
			.addCase(atraso.pending, (state) => {
				state.loadingAtraso = true
			})
			.addCase(atraso.fulfilled, (state, action) => {
				state.loadingAtraso = false
				state.atrasos = action.payload
			})
			.addCase(atraso.rejected, (state, action) => {
				state.message = action.payload
			})
	},
})

export const {
	showLoading,
} = boletoSlice.actions

export default boletoSlice.reducer