import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import RastreadorService from 'services/RastreadorService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const getAll = createAsyncThunk('rastreador/all',async () => {
    const response = await RastreadorService.all()
    return response;
})

export const getDisponivel = createAsyncThunk('rastreador/disponivel',async () => {
    const response = await RastreadorService.disponivel()
    return response;
})

export const save = createAsyncThunk('rastreador/save',async (data, { rejectWithValue }) => {
	try {
		const response = await RastreadorService.save(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao salvar usuário')
	}
})

export const id = createAsyncThunk('rastreador/id',async (data, { rejectWithValue }) => {
	try {
		const response = await RastreadorService.id(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao buscar rastreador')
	}
})

export const inativar = createAsyncThunk('rastreador/inativar',async (data, { rejectWithValue }) => {
	try {
		const response = await RastreadorService.inativar(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao inativar rastreador')
	}
})

export const enviarSms = createAsyncThunk('rastreador/enviarSms',async (data, { rejectWithValue }) => {
	try {
		const response = await RastreadorService.enviarSms(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao enviar sms')
	}
})

export const rastreadorSlice = createSlice({
	name: 'rastreador',
	initialState,
	reducers: {
		clearRastreadorEdicao: (state) => {
			state.edicao = null;
		  },
	},
    extraReducers: (builder) => {
		builder
			.addCase(getAll.pending, (state) => {
				state.loading = true
			})
			.addCase(getAll.fulfilled, (state, action) => {
				state.loading = false
				// state.redirect = '/'
				state.rastreadores = action.payload
			})
			.addCase(getAll.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(save.pending, (state) => {
				state.loading = true
			})
			.addCase(save.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/rastreador/default'
				state.edicao = null
			})
			.addCase(save.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(id.pending, (state) => {
				state.loading = true
			})
			.addCase(id.fulfilled, (state, action) => {
				state.loading = false
				// state.redirect = '/app/rastreador/default'
				state.edicao = action.payload
			})
			.addCase(id.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(getDisponivel.pending, (state) => {
				state.loading = true
			})
			.addCase(getDisponivel.fulfilled, (state, action) => {
				state.loading = false
				// state.redirect = '/app/rastreador/default'
				state.disponiveis = action.payload
			})
			.addCase(getDisponivel.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(inativar.pending, (state) => {
				state.loading = true
			})
			.addCase(inativar.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/cliente/default'
				state.edicao = action.payload
			})
			.addCase(inativar.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const {
	showLoading,
	clearRastreadorEdicao
} = rastreadorSlice.actions

export default rastreadorSlice.reducer