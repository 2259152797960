import fetch from 'auth/FetchInterceptor'

const empresaService = {}

empresaService.get = function () {
  return fetch({
    url: '/relatorio',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}
export default empresaService