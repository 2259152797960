import fetch from 'auth/FetchInterceptor'

const clienteService = {}

clienteService.all = function () {
  return fetch({
    url: '/sms/all',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

export default clienteService