import fetch from 'auth/FetchInterceptor'

const configuracaoService = {}

configuracaoService.statusWhatsapp = function () {
  return fetch({
    url: '/whatsapp/status',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

configuracaoService.conectar = function () {
  return fetch({
    url: '/whatsapp/conectar',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

configuracaoService.logout = function () {
  return fetch({
    url: '/whatsapp/logout',
    method: 'delete',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

export default configuracaoService