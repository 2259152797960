import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import ChecklistService from 'services/ChecklistService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const getAll = createAsyncThunk('checklist/all',async () => {
    const response = await ChecklistService.all()
    return response;
})

export const criar = createAsyncThunk('checklist/criar',async (data, { rejectWithValue }) => {
	try {
		const response = await ChecklistService.criar(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao criar checklist')
	}
})

export const getId = createAsyncThunk('checklist/id',async (data, { rejectWithValue }) => {
	try {
		const response = await ChecklistService.id(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao criar checklist')
	}
})

export const checklistService = createSlice({
	name: 'checklist',
	initialState,
	reducers: {
		
	},
    extraReducers: (builder) => {
		builder
		.addCase(getAll.pending, (state) => {
			state.loading = true
		})
		.addCase(getAll.fulfilled, (state, action) => {
			state.loading = false
			state.checklists = action.payload
		})
		.addCase(getAll.rejected, (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		})
		.addCase(getId.pending, (state) => {
			state.loading = true
		})
		.addCase(getId.fulfilled, (state, action) => {
			state.loading = false
			state.checklistId = action.payload
		})
		.addCase(getId.rejected, (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		})
	},
})

export const {
	showLoading,
} = checklistService.actions

export default checklistService.reducer