import fetch from 'auth/FetchInterceptor'

const clienteService = {}

clienteService.all = function () {
  return fetch({
    url: '/cliente/all',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

clienteService.save = function (data) {
  return fetch({
    url: '/cliente',
    method: 'post',
    data: data
  })
}

clienteService.id = function (data) {
  return fetch({
    url: `/cliente/${data}/id`,
    method: 'get',
    data: data
  })
}

clienteService.inativar = function (data) {
  return fetch({
    url: `/cliente/${data}/inativar`,
    method: 'put',
    data: data
  })
}

clienteService.reset = function (data) {
  return fetch({
    url: `/cliente/${data}/reset`,
    method: 'put',
    data: data
  })
}

clienteService.boasvindas = function (data) {
  return fetch({
    url: `/cliente/${data}/boas-vindas`,
    method: 'post',
    data: data
  })
}

clienteService.bloquear = function (data) {
  return fetch({
    url: `/cliente/${data}/bloquear`,
    method: 'put',
    data: data
  })
}

export default clienteService