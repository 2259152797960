import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import ClienteService from 'services/ClienteService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const getAll = createAsyncThunk('cliente/all',async () => {
    const response = await ClienteService.all()
    return response;
})


export const save = createAsyncThunk('cliente/save',async (data, { rejectWithValue }) => {
	try {
		const response = await ClienteService.save(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao salvar usuário')
	}
})

export const id = createAsyncThunk('cliente/id',async (data, { rejectWithValue }) => {
	try {
		const response = await ClienteService.id(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao salvar usuário')
	}
})

export const inativar = createAsyncThunk('cliente/inativar',async (data, { rejectWithValue }) => {
	try {
		const response = await ClienteService.inativar(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao salvar usuário')
	}
})

export const reset = createAsyncThunk('cliente/reset',async (data, { rejectWithValue }) => {
	try {
		const response = await ClienteService.reset(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao salvar usuário')
	}
})

export const bloquear = createAsyncThunk('cliente/bloquear',async (data, { rejectWithValue }) => {
	try {
		const response = await ClienteService.bloquear(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao salvar usuário')
	}
})

export const boasvindas = createAsyncThunk('cliente/boasvindas',async (data, { rejectWithValue }) => {
	try {
		const response = await ClienteService.boasvindas(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao enviar boas vindas')
	}
})


export const clienteSlice = createSlice({
	name: 'cliente',
	initialState,
	reducers: {
		clearClienteEdicao: (state) => {
			state.edicao = null;
		  },
	},
    extraReducers: (builder) => {
		builder
			.addCase(getAll.pending, (state) => {
				state.loading = true
			})
			.addCase(getAll.fulfilled, (state, action) => {
				state.loading = false
				// state.redirect = '/'
				state.users = action.payload
			})
			.addCase(getAll.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(save.pending, (state) => {
				state.loading = true
			})
			.addCase(save.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/cliente/default'
				// state.users = action.payload
				state.edicao = null
			})
			.addCase(save.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(id.pending, (state) => {
				state.loading = true
			})
			.addCase(id.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/cliente/default'
				state.edicao = action.payload
			})
			.addCase(id.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(inativar.pending, (state) => {
				state.loading = true
			})
			.addCase(inativar.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/cliente/default'
				state.edicao = action.payload
			})
			.addCase(inativar.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})

			.addCase(reset.pending, (state) => {
				state.loading = true
			})
			.addCase(reset.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/cliente/default'
				// state.edicao = action.payload
			})
			.addCase(reset.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(bloquear.pending, (state) => {
				state.loading = true
			})
			.addCase(bloquear.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/cliente/default'
				state.edicao = action.payload
			})
			.addCase(bloquear.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(boasvindas.pending, (state) => {
				state.loading = true
			})
			.addCase(boasvindas.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/cliente/default'
				state.edicao = action.payload
			})
			.addCase(boasvindas.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const {
	showLoading,
	clearClienteEdicao
} = clienteSlice.actions

export default clienteSlice.reducer