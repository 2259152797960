import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import RelatorioService from 'services/RelatorioService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const get = createAsyncThunk('relatorio/get',async () => {
    const response = await RelatorioService.get()
    return response;
})

export const relatorioSlice = createSlice({
	name: 'relatorio',
	initialState,
	reducers: {
		
	},
    extraReducers: (builder) => {
		builder
			.addCase(get.pending, (state) => {
				state.loading = true
			})
			.addCase(get.fulfilled, (state, action) => {
				state.loading = false
				state.relatorios = action.payload
			})
			.addCase(get.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const {
	showLoading,
} = relatorioSlice.actions

export default relatorioSlice.reducer