import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FinanceiroService from 'services/FinanceiroService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const saldo = createAsyncThunk('financeiro/saldo',async () => {
    const response = await FinanceiroService.saldo()
    return response;
})

export const saque = createAsyncThunk('financeiro/saque',async () => {
    const response = await FinanceiroService.saque()
    return response;
})

export const baixar = createAsyncThunk('financeiro/baixar',async (data, { rejectWithValue }) => {
	try {
		const response = await FinanceiroService.baixar(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao baixar saldo empresa')
	}
})

export const financeiroService = createSlice({
	name: 'financeiro',
	initialState,
	reducers: {
		
	},
    extraReducers: (builder) => {
		builder
			.addCase(saldo.pending, (state) => {
				state.loading = true
			})
			.addCase(saldo.fulfilled, (state, action) => {
				state.loading = false
				state.saldo = action.payload
			})
			.addCase(saldo.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const {
	showLoading,
} = financeiroService.actions

export default financeiroService.reducer