import fetch from 'auth/FetchInterceptor'

const financeiroService = {}

financeiroService.saldo = function () {
  return fetch({
    url: '/financeiro/saldo',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

financeiroService.baixar = function (data) {
  return fetch({
    url: `/financeiro/${data}/baixar-saldo`,
    method: 'post',
    data: data
  })
}

financeiroService.saque = function (data) {
  return fetch({
    url: `/financeiro/saque`,
    method: 'post',
    data: data
  })
}

export default financeiroService