import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import VeiculoService from 'services/VeiculoService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const getAll = createAsyncThunk('veiculo/all',async () => {
    const response = await VeiculoService.all()
    return response;
})

export const disponiveis = createAsyncThunk('veiculo/disponiveis',async () => {
    const response = await VeiculoService.disponiveis()
    return response;
})

export const id = createAsyncThunk('veiculo/id',async (data, { rejectWithValue }) => {
	try {
		const response = await VeiculoService.id(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao buscar veículo')
	}
})

export const save = createAsyncThunk('veiculo/save',async (data, { rejectWithValue }) => {
	try {
		const response = await VeiculoService.save(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao salvar veículo')
	}
})

export const veiculoSlice = createSlice({
	name: 'veiculo',
	initialState,
	reducers: {
		clearVeiculoEdicao: (state) => {
			state.edicao = null;
		  },
	},
    extraReducers: (builder) => {
		builder
			.addCase(getAll.pending, (state) => {
				state.loading = true
			})
			.addCase(getAll.fulfilled, (state, action) => {
				state.loading = false
				// state.redirect = '/'
				state.veiculos = action.payload
			})
			.addCase(getAll.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			}).addCase(disponiveis.pending, (state) => {
				state.loading = true
			})
			.addCase(disponiveis.fulfilled, (state, action) => {
				state.loading = false
				// state.redirect = '/'
				state.veiculosDisponiveis = action.payload
			})
			.addCase(disponiveis.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			}).addCase(id.pending, (state) => {
				state.loading = true
			})
			.addCase(id.fulfilled, (state, action) => {
				state.loading = false
				// state.redirect = '/app/cliente/default'
				state.edicao = action.payload
			})
			.addCase(id.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(save.pending, (state) => {
				state.loading = true
			})
			.addCase(save.fulfilled, (state, action) => {
				state.loading = false
				// state.redirect = '/app/cliente/default'
				// state.users = action.payload
				state.edicao = null
			})
			.addCase(save.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const {
	showLoading,
	clearVeiculoEdicao
} = veiculoSlice.actions

export default veiculoSlice.reducer