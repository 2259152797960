import fetch from 'auth/FetchInterceptor'

const empresaService = {}

empresaService.get = function () {
  return fetch({
    url: '/empresa',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

empresaService.cancelar = function (data) {
  return fetch({
    url: `/boleto/${data}/cancelar`,
    method: 'delete',
    data: data
  })
}

empresaService.reenviar = function (data) {
  return fetch({
    url: `/boleto/${data}/reenviar`,
    method: 'post',
    data: data
  })
}

export default empresaService