import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import ContratoService from 'services/ContratoService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const getAll = createAsyncThunk('contrato/all',async () => {
    const response = await ContratoService.all()
    return response;
})

export const getTestemunha = createAsyncThunk('contrato/testemunha',async () => {
    const response = await ContratoService.getTestemunha()
    return response;
})

export const testemunhaSave = createAsyncThunk('contrato/testemunhaSave',async (data, { rejectWithValue }) => {
	try {
		const response = await ContratoService.saveTestemunha(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao salvar testemunha')
	}
})

export const id = createAsyncThunk('contrato/id',async (data, { rejectWithValue }) => {
	try {
		const response = await ContratoService.id(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao buscar contrato')
	}
})

export const save = createAsyncThunk('contrato/save',async (data, { rejectWithValue }) => {
	try {
		const response = await ContratoService.save(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao salvar contrato')
	}
})

export const inativar = createAsyncThunk('contrato/inativar',async (data, { rejectWithValue }) => {
	try {
		const response = await ContratoService.inativar(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao salvar usuário')
	}
})

export const trocar = createAsyncThunk('contrato/trocar',async (data, { rejectWithValue }) => {
	try {
		const response = await ContratoService.trocar(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao salvar usuário')
	}
})

export const criarBoleto = createAsyncThunk('contrato/criarBoleto',async (data, { rejectWithValue }) => {
	try {
		const response = await ContratoService.criarBoleto(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao salvar usuário')
	}
})

export const atualizarContrato = createAsyncThunk('contrato/atualizar',async (data, { rejectWithValue }) => {
	try {
		const response = await ContratoService.atualizarContrato(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao atualizar contrato')
	}
})

export const criarJuridico = createAsyncThunk('contrato/criarJuridico',async (data, { rejectWithValue }) => {
	try {
		const response = await ContratoService.criarJuridico(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao salvar usuário')
	}
})

export const contratoSlice = createSlice({
	name: 'contrato',
	initialState,
	reducers: {
		clearContratoEdicao: (state) => {
			state.edicao = null;
		  },
	},
    extraReducers: (builder) => {
		builder
			.addCase(getAll.pending, (state) => {
				state.loading = true
			})
			.addCase(getAll.fulfilled, (state, action) => {
				state.loading = false
				// state.redirect = '/'
				state.contratos = action.payload
			})
			.addCase(getAll.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			}).addCase(id.pending, (state) => {
				state.loading = true
			})
			.addCase(id.fulfilled, (state, action) => {
				state.loading = false
				// state.redirect = '/app/cliente/default'
				state.edicao = action.payload
			})
			.addCase(id.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(save.pending, (state) => {
				state.loading = true
			})
			.addCase(save.fulfilled, (state, action) => {
				state.loading = false
				// state.redirect = '/app/cliente/default'
				// state.users = action.payload
				state.edicao = null
			})
			.addCase(save.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(inativar.pending, (state) => {
				state.loading = true
			})
			.addCase(inativar.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/contrato/default'
				// state.edicao = action.payload
			})
			.addCase(inativar.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(trocar.pending, (state) => {
				state.loading = true
			})
			.addCase(trocar.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/contrato/default'
				// state.edicao = action.payload
			})
			.addCase(trocar.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(criarBoleto.pending, (state) => {
				state.loading = true
			})
			.addCase(criarBoleto.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/contrato/default'
			})
			.addCase(criarBoleto.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(criarJuridico.pending, (state) => {
				state.loading = true
			})
			.addCase(criarJuridico.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/contrato/default'
			})
			.addCase(criarJuridico.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(getTestemunha.pending, (state) => {
				state.loading = true
			})
			.addCase(getTestemunha.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/contrato/default'
				state.testemunha = action.payload
			})
			.addCase(getTestemunha.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const {
	showLoading,
	clearContratoEdicao
} = contratoSlice.actions

export default contratoSlice.reducer