import { createSlice } from '@reduxjs/toolkit'
import { THEME_STORAGE } from 'constants/ThemeConstant';
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';

const storedThemeConfig = localStorage.getItem(THEME_STORAGE);

const initialState = storedThemeConfig ? JSON.parse(storedThemeConfig) : {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'pt',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	blankLayout: false
};

const themeSlice = createSlice({
	name: 'theme',
	initialState,
	reducers: {
		toggleCollapsedNav: (state, action) => {
			state.navCollapsed = action.payload;
			localStorage.setItem(THEME_STORAGE, JSON.stringify(state));
		},
		onNavStyleChange: (state, action) => {
			state.sideNavTheme = action.payload;
			localStorage.setItem(THEME_STORAGE, JSON.stringify(state));
		},
		onLocaleChange: (state, action) => {
			state.locale = action.payload;
			localStorage.setItem(THEME_STORAGE, JSON.stringify(state));
		},
		onNavTypeChange: (state, action) => {
			state.navType = action.payload;
			localStorage.setItem(THEME_STORAGE, JSON.stringify(state));
		},
		onTopNavColorChange: (state, action) => {
			state.topNavColor = action.payload;
			localStorage.setItem(THEME_STORAGE, JSON.stringify(state));
		},
		onHeaderNavColorChange: (state, action) => {
			state.headerNavColor = action.payload;
			localStorage.setItem(THEME_STORAGE, JSON.stringify(state));
		},
		onMobileNavToggle: (state, action) => {
			state.mobileNav = action.payload;
			localStorage.setItem(THEME_STORAGE, JSON.stringify(state));
		},
		onSwitchTheme: (state, action) => {
			state.currentTheme = action.payload;
			localStorage.setItem(THEME_STORAGE, JSON.stringify(state));
		},
		onDirectionChange: (state, action) => {
			state.direction = action.payload;
			localStorage.setItem(THEME_STORAGE, JSON.stringify(state));
		},
		onBlankLayout: (state, action) => {
			state.blankLayout = action.payload;
			localStorage.setItem(THEME_STORAGE, JSON.stringify(state));
		},
	},
});

export const {
	toggleCollapsedNav,
	onNavStyleChange,
	onLocaleChange,
	onNavTypeChange,
	onTopNavColorChange,
	onHeaderNavColorChange,
	onMobileNavToggle,
	onSwitchTheme,
	onDirectionChange,
	onBlankLayout
} = themeSlice.actions;

export default themeSlice.reducer;
