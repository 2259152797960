import fetch from 'auth/FetchInterceptor'

const rastreadorService = {}

rastreadorService.all = function () {
  return fetch({
    url: '/rastreador/all',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

rastreadorService.disponivel = function () {
  return fetch({
    url: '/rastreador/disponivel',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

rastreadorService.save = function (data) {
  return fetch({
    url: '/rastreador',
    method: 'post',
    data: data
  })
}

rastreadorService.id = function (data) {
  return fetch({
    url: `/rastreador/${data}/id`,
    method: 'get',
    data: data
  })
}

rastreadorService.inativar = function (data) {
  return fetch({
    url: `/rastreador/${data}/inativar`,
    method: 'put',
    data: data
  })
}

rastreadorService.enviarSms = function (data) {
  return fetch({
    url: `/rastreador/${data}/sms`,
    method: 'put',
    data: data
  })
}

export default rastreadorService