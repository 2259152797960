import fetch from 'auth/FetchInterceptor'

const whatsappService = {}

whatsappService.all = function () {
  return fetch({
    url: '/whatsapp/all',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

export default whatsappService